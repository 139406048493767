import { styled } from '@mui/system'
import { Box, Grid as MuiGrid } from '@mui/material'
import Typography from '../../atoms/typography'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['reverse', 'hasSiblings', 'aboveTheFold'].includes(prop)

const ImageColumn = styled('div')`
	width: 100%;
	${({ theme }) => theme.breakpoints.up('sm')} {
		order: ${({ reverse }) => (reverse ? '1' : '2')};
	}
`

const TextColumn = styled('div')`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: ${({ theme }) => theme.spacing(5, 3, 0)};
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: ${({ theme }) => theme.spacing(0, 6.5)};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(0, '6%', 0, '24%')};
	}
`

const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	margin-bottom: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-bottom: 32px;
	}
`

const Container = styled(Box, {
	shouldForwardProp,
})`
	display: flex;
	align-items: center;
	flex-direction: column;
	> ${ImageColumn} {
		height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		${({ theme }) => theme.breakpoints.up('sm')} {
			order: ${({ reverse }) => (reverse ? '1' : '2')};
		}
		.MuiLink-root {
			height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		}
	}
	${({ theme }) => theme.breakpoints.up('sm')} {
		display: grid;
		grid-template-columns: ${({ reverse }) => (reverse ? '57.22% 42.78%' : '42.78% 57.22%')};
		> ${TextColumn} {
			order: ${({ reverse }) => (reverse ? '2' : '1')};
		}
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	gap: 10px;
	padding-top: ${({ hasSiblings, theme }) => (hasSiblings ? theme.spacing(3) : 0)};
	flex-wrap: wrap;
	${({ theme }) => theme.breakpoints.up('md')} {
		gap: 10px 38px;
		padding-top: ${({ hasSiblings, theme }) => (hasSiblings ? theme.spacing(5.5) : 0)};
	}
`

const Title = styled(Typography)`
	font-family: ${({ theme }) => theme.typography.fontFamily};
	font-size: ${({ theme }) => theme.typography.pxToRem(40)};
	font-stretch: ${({ theme }) => theme.typography.fontStretchCondensed};
`

const Description = styled(Typography)`
	color: ${({ theme }) => theme.palette.grey.main};
	${({ theme }) => theme.breakpoints.up('md')} {
		padding-top: ${({ theme }) => theme.spacing(1.25)};
	}
`

const ButtonStyled = styled(Button)`
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	letter-spacing: -0.36px;
	line-height: 1;
	min-width: 0;
	padding: ${({ theme }) => theme.spacing(0.5, 2.75)};
`

export { Container, ImageColumn, TextColumn, CtaContainer, ButtonStyled, Description, Title, Subtitle }
